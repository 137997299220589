export enum TooltipMsg {
  NoAccessToDocument = "You do not have permission to edit this document",
  NoAccessToCategory = "You do not have permission to edit this category",
  NoAccessToTag = "You do not have permission to edit this Tag",
  NoAccessToMainCategory = "You cannot set this category as Main because you do not have access to it",
  NoPermission = "Permission is missing",
  NoPermissionForPopular = "You cannot set this category as Popular because you do not have permission to it",
  NotLatestRevision = "Not latest revision of the document",
  CantDeleteMainCategory = "You cannot delete Main Category. Please select different Main Category first",
  CannotSetMainForDeletedCategory = "You cannot set this category as main because it is deleted",
  CannotSetPopularForDeletedCategory = "You cannot set category as popular because it is deleted",
  DeleteCategory = "Delete category",
  UnsetPopular = "Document is Popular in selected category. Click to remove it",
  SetPopular = "Set document as Popular in this category",
  MainCategory = "Main category of a document",
  SetMainCategory = "Set this category as Main",
  MainCategoryRequiresApproval = "Main category cannot be changed because it requires approval",
  SectionDisabled = "This document section is disabled",
  LinkedDocument = "Linked document",
  ServiceIntegration = "Service Integration",
  Clear = "Clear",
  Search = "Search",
  OpenCalendar = "Open calendar",
  PreviousMonth = "Previous month",
  NextMonth = "Next month",
  Resume = "Resume",
  Pause = "Pause",
  Stop = "Stop",
  Move = "Move",
  Restore = "Restore",
  Maximize = "Maximize",
  Collapse = "Collapse",
  Expand = "Expand",
  DiscardChanges = "Discard changes",
  PreviousPage = "Previous page",
  NextPage = "Next page",
  Swap = "Swap",
  Help = "Help",
  Open = "Open",
  Close = "Close",
  Hide = "Hide",
  MovingDisabled = "Moving is disabled",
  CopyWholePath = "Copy whole path",
  ShowWholePath = "Show whole path",
  CollapseAllBreadcrumbs = "Collapse all breadcrumbs",
  ExpandAllBreadcrumbs = "Expand all breadcrumbs",
  CannotApprovePendingApproval = "Document has a revision pending approval. It can only be changed by Approver until approval process is finished.",
  BreakDocumentLink = "You do not have access to the main category, or this document is not archived or published",
  DeleteDocument = "You do not have permission to delete this document",
  UndeleteDocument = "You do not have permission to undelete this document",
  ColumnCannotBeRemoved = "This field cannot be removed",
  DocumentsGroup = "Documents group",
  NewLanguageRevision = "This is a new language version of the document, this field cannot be edited",
  NewPartRevision = "This is a new part version of the document, this field cannot be edited",
  NewFromTemplate = "This is a new document from template, this field cannot be edited",
  NewRevision = "This is a new revision of the document, this field cannot be edited",
  PendingApproval = "Document is in 'In Approval' state. It can only be published, not saved as draft.",
  NoApproverRights = "You do not have Approver rights in document's Main Category",
  CategoryWithoutApproval = "You do not have Approver rights in this category",
  CategoriesWithoutApproval = "Some categories in the document require Approver rights",
  RevisionHasPendingApprovals = "Document has a revision pending approval. New revision can be created only after approval process is finished.",
  DeletedDocument = "This document is deleted, it cannot be edited",
  RemoveTag = "Click to remove tag value",
  WillNotBeLatestRevisionText = "This document will not be the latest revision when published",
  AllRevisionsHaveBeenUsed = "A new revision cannot be created because all possible revisions have been used",
  NoChangesMade = "No changes made",
  IsSomeValidationErrors = "You cannot save changes because there are validation errors",
  ChooseCategoryFirst = "No category selected",
  ChooseTagFirst = "No Tag selected",
  UserIsCategoryOwner = "Category you are owner of",
  UserIsNotCategoryOwner = "Category you are not owner of",
  UserIsCategoryOwnerInside = "Category containing category you are owner of",
  UncompletedSelfHelpSections = "This button will become active once all help sections are read",
  DialogControlNoTags = "No Tag is selected",
  AutoCompleteQualityIssue = "This value has data quality issue",
  CategoryItemHasNoPublishRight = "Category you cannot publish in",
  CategoryItemHasPermissionsInside = "Category containing category you can publish in",
  CategoryItemHasPublishRights = "Category you can publish in",
  CategoryItemRefreshIcon = "Refresh document counts in categories below",
  CategoryItemErrorIcon = "Counting failed, you can refresh it on parent category",
  CategoryItemHighLevelCategory = "This is a high level category",
  ResetFilters = "Reset filters",
  DocumentHeaderServiceIntegration = "Document published by an external application",
  DocumentHeaderNoAccess = "You do not have access to this document and any of its categories",
  DocumentHeaderDeleted = "This document is deleted",
  ValidationStatusWarnings = "(Warnings)",
  ValidationStatusErrors = "(Errors)",
  ValidationStatusModified = "(Modified)",
  ValidationStatusModifiedErrors = "(Modified with Errors)",
  ValidationStatusModifiedWarnings = "(Modified with Warnings)",
  CategoryFromExternalApp = "Category provided by external application",
  Versions = " versions",
  DeletePermanently = "Delete this document permanently",
  TemporaryLinkDisclaimer = "This action breaks temporary document link so it disappears from search engines faster (Google, Bing etc.)",
  NewDocument = "Create new document from scratch",
  ExportMetadata = "Export metadata of documents matching filter criteria to Excel format",
  ChangeGrouping = "Change list view to either non grouped or grouped",
  ColumnsVisibility = "Set columns visibility in documents list",
  ExpandCollapseGroups = "Expand or collapse document list groups",
  ResetView = "Reset view to default values",
  ChangeDensity = "Change document list density",
  Publishers = "Open view to see access rights assigned to Publishers",
  AccessGroupsManagement = "Open Access Groups Management application",
  CategorySettings = "Open Category settings",
  HelpMaterials = "ABB Library Publishing Interface help materials",
  Feedback = "Provide a feedback about the tool itself",
  DocumentKindsInfo = "List of document kinds with descriptions",
  SelfHelpCompleted = "Completed self-help",
  SelfHelpMandatory = "Mandatory self-help",
  SelfHelpOptional = "Optional self-help",
  EmptyDocumentNumber = "Document number is empty",
  CantPublish = "No publisher access",
  UserIsTagOwner = "Tag you are owner of",
  UserIsNotTagOwner = "Tag you are not owner of",
  CannotRemoveTag = "You're not an owner of this tag",
}

import { TooltipTop } from "../../../../../controls/Tooltips/ToolipTop";
import { DocumentInfoDiv } from "./styledComponents/DocumentInfoDiv";
import {
  StyledElectricalServicesIcon,
  StyledEventNoteIcon,
  StyledLinkIcon,
  StyledLockIcon,
  StyledPublicIcon,
} from "./styledComponents/DocumentInfoIcons";

export interface DocumentInfoProps {
  hasAccess: boolean;
  isLinked: boolean;
  isFromServiceIntegration: boolean;
  isCMA: boolean;
  hasScheduledAction: boolean;
}

const getId = (
  isLinked: boolean,
  isFromServiceIntegration: boolean,
  isCMA: boolean
): string => {
  let id = "";
  if (isLinked) {
    id = id.concat("_Linked");
  }

  if (isFromServiceIntegration) {
    id = id.concat("_FromServiceIntegration");
  }

  if (isCMA) {
    id = id.concat("_CMA");
  }

  return id;
};

export function DocumentInfo(props: DocumentInfoProps) {
  return (
    <DocumentInfoDiv
      id={getId(props.isLinked, props.isFromServiceIntegration, props.isCMA)}
    >
      {!props.hasAccess && !props.isLinked && (
        <TooltipTop title="No access to any category in this document">
          <StyledLockIcon />
        </TooltipTop>
      )}
      {!props.hasAccess && props.isLinked && (
        <TooltipTop title="No access to Main Category in this document">
          <StyledLinkIcon />
        </TooltipTop>
      )}
      {props.isFromServiceIntegration && (
        <TooltipTop title="Document created by external application">
          <StyledElectricalServicesIcon />
        </TooltipTop>
      )}
      {props.isCMA && (
        <TooltipTop title="Country restrictions applied">
          <StyledPublicIcon />
        </TooltipTop>
      )}
      {props.hasScheduledAction && (
        <TooltipTop title="Document has a scheduled event">
          <StyledEventNoteIcon />
        </TooltipTop>
      )}
    </DocumentInfoDiv>
  );
}

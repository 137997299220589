import { useEffect, useMemo } from "react";
import { useSetTagValuesMutation } from "../../apis/tagsApi";
import { useTagValues } from "../../components/tags/TagValues/hooks/useTagValues";
import { SnackbarType } from "../../models/snackbar";
import { openSnackbar } from "../helpers/snackBarHelper";
import {
  selectAddedTags,
  selectRemovedTags,
  clearTagValues,
} from "../slices/tagsSlice";
import { selectSelectedTag } from "../slices/tagsViewSlice";
import { useAppSelector, useAppDispatch } from "./hooks";
import { ErrorApi, ErrorApiResponse } from "../../models/errorApi";
import { ErrorApiType } from "../../models/errorApiType";
import { find } from "lodash";

export function useTagChanges() {
  const selectedTag = useAppSelector(selectSelectedTag);
  const addedTagValues = useAppSelector(selectAddedTags);
  const removedTagValues = useAppSelector(selectRemovedTags);
  const dispatch = useAppDispatch();

  const { fetchTagValues } = useTagValues();

  const isSuccessText = "Saved new Tag Values successfully.";
  const isErrorText = "Error while saving new Tag Values.";

  const [setTagValues, { isSuccess, isError, isLoading, error, reset }] =
    useSetTagValuesMutation();

  const onSaveHandler = () => {
    if (selectedTag) {
      void setTagValues({
        tagId: selectedTag.id,
        addedValues: addedTagValues.map((x) => x.value),
        removedValues: removedTagValues.map((x) => x.value),
      });
    }
  };

  const errors = useMemo(() => {
    if (isError) {
      return !!error &&
        "status" in error &&
        !!(error.data as ErrorApiResponse | undefined)?.errors
        ? (error.data as ErrorApiResponse).errors
        : [{ message: "", code: ErrorApiType.Exception }];
    }

    return [];
  }, [isError, error]);

  useEffect(() => {
    if (isError) {
      let title: string = isErrorText;

      const indexingError = find(errors, (error: ErrorApi) => {
        return (
          error.code === ErrorApiType.Exception ||
          error.code === ErrorApiType.TagValueError
        );
      });

      if (indexingError) {
        title = indexingError.message;
      }
      openSnackbar(dispatch, title, SnackbarType.error);
      reset();
    }
  }, [dispatch, isError, reset, errors]);

  useEffect(() => {
    if (isSuccess) {
      openSnackbar(dispatch, isSuccessText, SnackbarType.success);
      fetchTagValues();
      reset();
      dispatch(clearTagValues());
    }
  }, [dispatch, isSuccess, fetchTagValues, reset]);

  return {
    onSaveHandler,
    isLoading,
  };
}
